<template>
     <div class="container-fluid" v-if="sale">
         <SuccessAlert ref="MySuccessAlert" :title="AlertTitle" :message="AlertMessage" :back="true"/>
         <BillModal ref="MyBillModal" :BillInfo="BillInfo" :SalesItems="sale.data.ProductsSale"  :back="false"/>
         
          <div class="row row-custom">
            <div class="col-md-12">
              <div class="card">
                <div class="card-header card-header-primary">
                  <h4 class="card-title">تغيير حالة الطلب</h4>
                 </div>
                <div class="card-body">
                     <div class="row mb-3">
                          <Select class_bs="col-md-12" id="status_id" title="التصنيف" :Items="CustomStatus" Mykey="status_id" :errors="service_error" :MyValue="form.status_id" @Select="HandleSelect('status_id',$event)"/>
                          <template v-if="SelectStatus && SelectStatus.action_type == 'show_sale_items'">
                            <InputToggle class_bs="col-md-12" v-model="ProductsToggleArray[index].val" :MyValue="ProductsToggleArray[index].val" v-for="(product,index) in CustomProduct" :key="index"  :id="product.id" :title="product.name" :errors="service_error" 
                                @GiveMeIfo="HandleSaleProduct($event,index)"
                           />
                         </template>


                         <template v-if="sale.data.delivery_method == '1' && form.status_id == 4">


                    <Select   id="servicePackageId" title="servicePackageId" :Items="CustomServicePackagesList" Mykey="servicePackageId" :errors="service_error" :MyValue="sale.data.servicePackageId" @Select="changeSaleValues('servicePackageId',$event)"/>

                    <Input id="title" title="عنوان الفاتورة" @Input="changeSaleValues('title',$event)" Mykey="title" :errors="service_error" :MyValue="sale.data.title"/>
                    <InputToggle  v-model="sale.data.pickFromDoor" :MyValue="sale.data.pickFromDoor" id="pickFromDoor" title="الاستلام من المحل" :errors="service_error" 
                                                    @GiveMeIfo="changeSaleValues('pickFromDoor',$event)"
                                              />
                    <InputToggle  v-model="sale.data.dropToDoor" :MyValue="sale.data.dropToDoor" id="dropToDoor" title="التوصيل إلى المنزل" :errors="service_error" 
                                                    @GiveMeIfo="changeSaleValues('dropToDoor',$event)"
                                              />   
                    <Select   id="destination_from_city" title="عنوان المتجر" :Items="CustomCityNodeList" Mykey="destination.from.city" :errors="service_error" :MyValue="sale.data.destination_from_city" @Select="changeSaleValues('destination_from_city',$event)"/>
                    <Input id="destination_from_address" title="عنوان المتجر لطلب التجميع" @Input="changeSaleValues('destination_from_address',$event)" Mykey="destination.from.address" :errors="service_error" :MyValue="sale.data.destination_from_address"/>

                    <Select   id="destination_to_city" title="الى المدينة" :Items="CustomCityNodeList" Mykey="destination.to.city" :errors="service_error" :MyValue="sale.data.destination_to_city" @Select="changeSaleValues('destination_to_city',$event)"/>
                    <Input id="destination_to_address" title="الى العنوان" @Input="changeSaleValues('destination_to_address',$event)" Mykey="destination.to.address" :errors="service_error" :MyValue="sale.data.destination_to_address"/>

                    <Input id="receivers_full_name" title="الاسم الكامل للمستلم" @Input="changeSaleValues('receivers_full_name',$event)" Mykey="receivers_full_name" :errors="service_error" :MyValue="sale.data.receivers_full_name"/>
                    <Input id="receivers_contact" title="رقم هاتف المستلم" @Input="changeSaleValues('receivers_contact',$event)" Mykey="receivers_contact" :errors="service_error" :MyValue="sale.data.receivers_contact"/>

                    <Select   id="paymentBy" title="من سيدفع مبلغ التوصيل" :Items="paymentByOptions" Mykey="paymentBy" :errors="service_error" :MyValue="sale.data.paymentBy" @Select="changeSaleValues('paymentBy',$event)"/>
                    <Select   id="productPayment" title="هل على المستلم دفع قيمة المنتج" :Items="productPaymentOptions" Mykey="productPayment" :errors="service_error" :MyValue="sale.data.productPayment" @Select="changeSaleValues('productPayment',$event)"/>
                    <Select   id="paymentMethod" title="طريقة الدفع" :Items="paymentMethodOptions" Mykey="paymentMethod" :errors="service_error" :MyValue="sale.data.paymentMethod" @Select="changeSaleValues('paymentMethod',$event)"/>
                    <InputToggle  v-model="sale.data.allowBankPayment" :MyValue="sale.data.allowBankPayment" id="allowBankPayment" title="allowBankPayment" :errors="service_error" 
                                                                        @GiveMeIfo="changeSaleValues('allowBankPayment',$event)"
                                                                  />
                    <InputArea id="notes" title="ملاحظات" @Input="changeSaleValues('notes',$event)" Mykey="notes" :errors="service_error" :MyValue="sale.data.notes"/>


                           
 


                    <div class="card">
                      <div class="card-header card-header-primary" style="text-align: center;">
                        <h4 class="card-title ">المنتجات بالفاتورة</h4>
                      </div>
                      <div class="card-body" style="text-align:center;">
                        <div class="table-responsive">
                          <table class="table">
                            <thead class=" text-primary">
                              <th>
                                #
                              </th>
                              <th>
                                اسم المنتج
                              </th>
 
                              <th>
                               المبلغ     
                              </th>
                              <th>
                               السماحية برد المنتج
                              </th>
                              <th>
                                الكمية
                              </th>
                              <th>
                                الارتفاع
                              </th>
                              <th>
                                الطول
                              </th>
                              <th>
                                وحدة الطول
                              </th>
                              <th>
                                العرض
                              </th>
                              <!-- <th>
                                sku
                              </th> -->
                              
                              <th>
                                وحدة الوزن
                              </th>
                              <th>
                                الوزن
                              </th>

                            </thead>
                            <tbody>
                              <tr v-for="(item,index) in sale.data.ProductsSale" :key="index">
                                <td style="min-width:15px;">
                                  {{index+1}}
                                </td>

                                 <td style="min-width:200px;">
                                   <Input class_bs="col-md-12" :id="'prod_title'+index" title="اسم المنتج" @Input="changeProductsSaleValues(index,'prod_title',$event)" :Mykey="'products.'+index+'.title'" :errors="service_error" :MyValue="item.prod_title"/>
                                </td>
 
                                <td style="min-width:200px;">
                                
                                    <Input class_bs="col-md-12" :id="'prod_amount'+index" title="المبلغ" @Input="changeProductsSaleValues(index,'prod_amount',$event)" :Mykey="'products.'+index+'.amount'" :errors="service_error" :MyValue="item.prod_amount"/>
                                </td>
                                <td style="min-width:200px;">
                                   <InputToggle :withTitle="false"  v-model="item.prod_isRefundable" :MyValue="item.prod_isRefundable" :id="'prod_isRefundable'+index" title="السماحية برد المنتج" :errors="service_error" 
                                                                        @GiveMeIfo="changeProductsSaleValues(index,'prod_isRefundable',$event)"
                                                                  />
                                </td>
                                <td style="min-width:200px;">
                                  <Input class_bs="col-md-12" :id="'prod_quantity'+index" title="الكمية" @Input="changeProductsSaleValues(index,'prod_quantity',$event)" :Mykey="'products.'+index+'.quantity'" :errors="service_error" :MyValue="item.prod_quantity"/>
                                </td>
                                <td style="min-width:200px;">
                                   <Input class_bs="col-md-12" :id="'prod_size_height'+index" title="الارتفاع" @Input="changeProductsSaleValues(index,'prod_size_height',$event)" :Mykey="'products.'+index+'.size.height'" :errors="service_error" :MyValue="item.prod_size_height"/>
                                </td>
                                <td style="min-width:200px;">
                                   <Input class_bs="col-md-12" :id="'prod_size_length'+index" title="الطول" @Input="changeProductsSaleValues(index,'prod_size_length',$event)" :Mykey="'products.'+index+'.size.length'" :errors="service_error" :MyValue="item.prod_size_length"/>
                                </td>
                                <td style="min-width:200px;">
                                  <Select class_bs="col-md-12"   :id="'prod_size_scale'+index" title="وحدة الطول" :Items="sizeScaleOptions" :Mykey="'products.'+index+'.size.scale'" :errors="service_error" :MyValue="item.prod_size_scale" @Select="changeProductsSaleValues(index,'prod_size_scale',$event)"/>
                                </td>
                                <td style="min-width:200px;">
                                    <Input class_bs="col-md-12" :id="'prod_size_width'+index" title="العرض" @Input="changeProductsSaleValues(index,'prod_size_width',$event)" :Mykey="'products.'+index+'.size.width'" :errors="service_error" :MyValue="item.prod_size_width"/>                                  
                                </td>
                                <!-- <td style="min-width:200px;">
                                   <Input class_bs="col-md-12" :id="'prod_sku'+index" title="prod_sku" @Input="changeProductsSaleValues(index,'prod_sku',$event)" :Mykey="'products.'+index+'.sku'" :errors="service_error" :MyValue="item.prod_sku"/>
                                </td> -->
                                
                                <td style="min-width:200px;">
                                  <Select class_bs="col-md-12"   :id="'prod_weight_scale'+index" title="وحدة الوزن" :Items="weightScaleOptions" :Mykey="'products.'+index+'.weight.scale'" :errors="service_error" :MyValue="item.prod_weight_scale" @Select="changeProductsSaleValues(index,'prod_weight_scale',$event)"/>
                                </td>
                                <td style="min-width:200px;">
                                    <Input class_bs="col-md-12" :id="'prod_weight_value'+index" title="الوزن" @Input="changeProductsSaleValues(index,'prod_weight_value',$event)" :Mykey="'products.'+index+'.weight.value'" :errors="service_error" :MyValue="item.prod_weight_value"/>                                
                                </td>
 
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                
                         </template>
                     </div>

                    <button type="button" class="btn btn-primary pull-right"    @click="onSubmit()">حفظ </button>
                    <button type="button" class="btn btn-primary pull-right"    @click="showBillModal()">عرض الفاتورة </button>
              
                      <div class="clearfix"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
</template>

<script>
import {mapState,mapMutations} from 'vuex';
import store from '../../../store/index'
import SuccessAlert from '../../../components/SuccessAlert'
import Select from '../../../components/Form/TextSelect'
import InputToggle from '../../../components/Form/InputToggle'
import Input from '../../../components/Form/TextInput'
import InputArea from '../../../components/Form/TextArea'
import BillModal from '../../../components/BillModal'

export default {
  computed:{
    ...mapState({
        service_error:state=>state.admin.sale.Sale.errors,
 
        StatusList:state=>state.admin.status.StatusList,
        sale:state=>state.admin.sale.Sale,



    }),
    BillInfo(){
      if(this.sale){
      return {
        client_name:this.sale.data.client.first_name + ' ' + this.sale.data.client.last_name,
        request_created_at:this.sale.data.created_at.slice(0,10),
      };
      }else{
        return {}
      }
    },

    paymentMethodOptions(){
      return [
        {
          id:'Cash',
          name:'كاش',
        },
        {
          id:'Sales',
          name:'بطاقة مصرفية',
        },
      ]
    },
    paymentByOptions(){
      return [
        {
          id:'Sender',
          name:'المحل',
        },
        {
          id:'Receiver',
          name:'المستلم',
        },
      ]
    },

    productPaymentOptions(){
      return [
        {
          id:'Unincluded',
          name:'لا',
        },
        {
          id:'Included',
          name:'نعم',
        },
      ]
    },

    weightScaleOptions(){
      return [
        {
          id:'Kg',
          name:'Kg',
        },
        {
          id:'Lb',
          name:'Lb',
        },
      ]
    },
    sizeScaleOptions(){
        return [
          {
            id:'Centimeter',
            name:'السنتيمتر',
          },
          {
            id:'Meter',
            name:'المتر',
          },
        ]
      },

    CustomStatus(){
        let Status=[];
        this.StatusList.data.forEach(element => {
            Status.push({
                id          : element.id,
                name        : element.name.ar,
                action      : element.action,
                action_type : element.action_type,
            })
        });
        return Status;
    },
    SelectStatus(){
     return this.StatusList.data.find(e=>e.id == this.form.status_id);
    },

    CustomProduct(){
      let Products=[];
      this.sale.data.ProductsSale.forEach(element => {
        Products.push({
          id:element.product.id,
          name:element.product.name.ar
        })
      });
      return Products;
    }
 
   },
   data(){
       return{
           form:{
               uuid:'',//sale uuid
               status_id:'',
               SelectedProducts:[],
           },
           ProductsToggleArray:[],
           AlertTitle:'',
           AlertMessage:'',


           CustomCityNodeList:[],
           CustomServicePackagesList:[],
 
       }
   },
   methods:{
    ...mapMutations({
          cleanErrors: "admin/cleanSaleErrors"
       }),
       showBillModal(){
         this.$refs.MyBillModal.showModel();
       },
 
      changeProductsSaleValues(index,key,value){
        if(key == 'prod_isRefundable')
         this.$store.commit('admin/changeProductsSaleValues',{index:index,key:key,value:value.val});
        else
         this.$store.commit('admin/changeProductsSaleValues',{index:index,key:key,value:value});
      },

      changeSaleValues(key,value){
        if(key == 'pickFromDoor' || key == 'dropToDoor' || key == 'allowBankPayment')
         this.$store.commit('admin/changeSaleValues',{key:key,value:value.val});
        else
         this.$store.commit('admin/changeSaleValues',{key:key,value:value});
      },
       HandleSelect(key,value){
        this.form[key]=value;
      },
      HandleSaleProduct(value,index){
         this.ProductsToggleArray[index].val=value.val;
         if(value.status=="push"){
          this.form.SelectedProducts.push(value.id); 
         }
         else{
           var index = this.form.SelectedProducts.indexOf(value.id);
          if (index > -1) {
              this.form.SelectedProducts.splice(index, 1);
          }
         }
         
       },


       
       onSubmit(){
          let formData=new FormData(); 
          for (let key in this.form) {
            if(key == 'SelectedProducts'){
              for (let index = 0; index < this.form.SelectedProducts.length; index++) {
                formData.append('SelectedProducts['+index+']',this.form.SelectedProducts[index]); 
              }
            }else{
               formData.append(key, this.form[key]); 
            }  
          }

          if(this.sale.data.delivery_method == '1' && this.form.status_id == 4){
          for (let key in this.sale.data) {
            if(key == 'ProductsSale'){
              for (let index = 0; index < this.sale.data.ProductsSale.length; index++) {
                  formData.append('products['+index+'][products_sale_id]',this.sale.data.ProductsSale[index]['id']);
                  formData.append('products['+index+'][sku]',this.sale.data.ProductsSale[index]['prod_sku']?this.sale.data.ProductsSale[index]['prod_sku']:this.sale.data.ProductsSale[index]['id']);
                  formData.append('products['+index+'][title]',this.sale.data.ProductsSale[index]['prod_title']?this.sale.data.ProductsSale[index]['prod_title']:''); 
                  formData.append('products['+index+'][amount]',this.sale.data.ProductsSale[index]['prod_amount']?this.sale.data.ProductsSale[index]['prod_amount']:''); 
                  formData.append('products['+index+'][quantity]',this.sale.data.ProductsSale[index]['prod_quantity']?this.sale.data.ProductsSale[index]['prod_quantity']:''); 
                  formData.append('products['+index+'][isRefundable]',this.sale.data.ProductsSale[index]['prod_isRefundable']?this.sale.data.ProductsSale[index]['prod_isRefundable']:''); 
                  formData.append('products['+index+'][size][scale]',this.sale.data.ProductsSale[index]['prod_size_scale']?this.sale.data.ProductsSale[index]['prod_size_scale']:''); 
                  formData.append('products['+index+'][size][width]',this.sale.data.ProductsSale[index]['prod_size_width']?this.sale.data.ProductsSale[index]['prod_size_width']:''); 
                  formData.append('products['+index+'][size][height]',this.sale.data.ProductsSale[index]['prod_size_height']?this.sale.data.ProductsSale[index]['prod_size_height']:''); 
                  formData.append('products['+index+'][size][length]',this.sale.data.ProductsSale[index]['prod_size_length']?this.sale.data.ProductsSale[index]['prod_size_length']:''); 
                  formData.append('products['+index+'][weight][scale]',this.sale.data.ProductsSale[index]['prod_weight_scale']?this.sale.data.ProductsSale[index]['prod_weight_scale']:''); 
                  formData.append('products['+index+'][weight][value]',this.sale.data.ProductsSale[index]['prod_weight_value']?this.sale.data.ProductsSale[index]['prod_weight_value']:''); 
              }
            }else if(key == 'receivers_full_name'){
              formData.append('receivers[0][fullName]', this.sale.data[key]?this.sale.data[key]:''); 
            }else if(key == 'receivers_contact'){
              formData.append('receivers[0][contact]', this.sale.data[key]?this.sale.data[key]:''); 
            }else if(key == 'servicePackageId'){
               formData.append('servicePackageId', this.sale.data[key]?this.sale.data[key]:''); 
            }else if(key == 'title'){
               formData.append('title', this.sale.data[key]?this.sale.data[key]:''); 
            }else if(key == 'pickFromDoor'){
               formData.append('pickFromDoor', this.sale.data[key]); 
            }else if(key == 'dropToDoor'){
               formData.append('dropToDoor', this.sale.data[key]); 
            }else if(key == 'destination_from_city'){
               formData.append('destination[from][city]', this.sale.data[key]?this.sale.data[key]:''); 
            }else if(key == 'destination_from_address'){
               formData.append('destination[from][address]', this.sale.data[key]?this.sale.data[key]:''); 
            }else if(key == 'destination_to_city'){
               formData.append('destination[to][city]', this.sale.data[key]?this.sale.data[key]:''); 
            }else if(key == 'destination_to_address'){
               formData.append('destination[to][address]', this.sale.data[key]?this.sale.data[key]:''); 
            }else if(key == 'paymentBy'){
               formData.append('paymentBy', this.sale.data[key]?this.sale.data[key]:''); 
            }else if(key == 'productPayment'){
               formData.append('productPayment', this.sale.data[key]?this.sale.data[key]:''); 
            }else if(key == 'paymentMethod'){
               formData.append('paymentMethod', this.sale.data[key]?this.sale.data[key]:''); 
            }else if(key == 'allowBankPayment'){
               formData.append('allowBankPayment', this.sale.data[key]); 
            }else if(key == 'notes'){
               formData.append('notes', this.sale.data[key]?this.sale.data[key]:''); 
            }else if(key == 'delivery_method'){
               formData.append('delivery_method', this.sale.data[key]?this.sale.data[key]:''); 
            }    
          }
          }

          if(this.sale.data.delivery_method == '1' && this.form.status_id == 4 && this.sale.data.is_send == '1'){
                this.AlertTitle="معلومات",
                this.AlertMessage="تم ارسال الطلب من قبل !";
                this.$refs.MySuccessAlert.showModel();
          }else{
            formData.append('_method','patch'); 
          //  store.commit('admin/PleaseStartLoading');
            store.dispatch('admin/updateSaleStatus',formData).then((response)=>{
              store.dispatch('admin/fetchSale', this.form.uuid).then((responseSale) => {
             store.commit('admin/PleaseStopLoading');
              this.cleanErrors();
              this.AlertTitle="معلومات",
              this.AlertMessage="تم حفظ التغيرات بنجاح";
              this.$refs.MySuccessAlert.showModel();

              }).catch((error)=>{
            store.commit('admin/PleaseStopLoading');
            });
            }).catch((error)=>{
            store.commit('admin/PleaseStopLoading');
            });
          }
          
       },
 
 
   },
   components:{
        SuccessAlert,
        Select,
        InputToggle,
        Input,
        InputArea,
        BillModal
    },
    created(){
      this.CustomCityNodeList=[],
      this.CustomServicePackagesList=[],
      store.dispatch('admin/getCitiesNodeList',{limit:4})
				.then((responseNodeList) => {	
            let data= responseNodeList.data.data;
            data.forEach(element => {
              this.CustomCityNodeList.push({
                id:element.node,
                name:element.node,
              });
            });              
				store.dispatch('admin/getServicePackagesList',{limit:4})
				.then((responsePackagesList) => {			
          let data1= responsePackagesList.data.data;

            data1.forEach(element => {
              this.CustomServicePackagesList.push({
                id:element.servicePackageId,
                name:element.title,
              });
            });


					})
				.catch((error) => {
				});
				})
				.catch((error) => {
				});
    },
   
     beforeRouteEnter(to,from,next){  
      //   store.commit('admin/PleaseStartLoading');
        const uuid = to.params.uuid; 
        store.dispatch('admin/fetchStatusList', {limit:99}).then((responseStatus) => {
            store.dispatch('admin/fetchSale', uuid).then((responseSale) => {
                next(vm => {
                    vm.form.uuid=uuid;
                    vm.form.status_id=responseSale.data.data.status.id;

                    vm.sale.data.ProductsSale.forEach(element => {
                        
                        if(element.status == 0){
                           vm.form.SelectedProducts.push(element.product.id); 
                        }
                        
                        vm.ProductsToggleArray.push({
                          id:element.product.id,
                          val:element.status == 0?true:false,
                        });
                  });

                });
            });
        });
 
    },
      beforeDestroy(){
         this.cleanErrors();
     }
}
</script>

<style lang="scss">
/*
 *
 */
.row-custom{
    justify-content: center;
}


















</style>