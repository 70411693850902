<template>
<!-- Modal HTML -->
<div id="SalesBillModal" class="modal fade">
	<div class="modal-dialog modal-confirm">
		<div class="modal-content">
			<div class="modal-header justify-content-center">
 
				<button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>
			</div>
			<div class="modal-body text-center">
 
           
              <div id="bill">
                <div class="row bill-header">
                    <div class="col-md-12 bill-logo">
                        <img src="/assets/img/logo.png"  alt="logo" class="center-logo">
                        <span>القدرة الرقمية للاكترونات</span>
                    </div>
                    <div class="col-md-6 text-right">
                        <span class="bill-title">اسم الزبون</span>
                        <span  class="bill-value">{{BillInfo.client_name}}</span>
                    </div>
                    <div class="col-md-6 text-left">
                        <span class="bill-title">تاريخ الطلبية</span>
                        <span  class="bill-value">{{BillInfo.request_created_at}}</span>
                    </div>

                    <div class="col-md-12 text-right">
                        <span class="bill-title">تاريخ اصدار الفاتورة</span>
                        <span  class="bill-value">{{current_date}}</span>
                    </div>
                </div>
                <div class="row">
                   <table class="table">
                       <thead>
                       <th>#</th>
                       <th>اسم المنتج</th>
                       <th>الكمية</th>
                       <th>سعر</th>
                       <th>الاجمالى</th>
                       </thead>
                       <tbody>
                           <tr v-for="(item,index) in SalesItems" :key="index">
                                <td>{{index +1}}</td>
                                <td>{{item.product.name['ar']}}</td>
                                <td>{{item.quantity}}</td>
                                <td>{{item.selling_price_after_discount}}</td>
                                <td>{{item.selling_price_after_discount * item.quantity}}</td>
                           </tr>
                       </tbody>
                   </table> 
                </div>
                <div class="row mb-3">
                    <div class="col-md-12 text-left">
                        <span class="bill-title">الاجمالي :</span>
                        <span>{{total}} د.ل</span>
                    </div>
                </div>

                <div class="row mb-3">
                    <div class="col-md-12 text-center">
                        <span class="bill-title">شكرً لزيارتكم</span>
                         
                    </div>
                </div>

            </div>

 


                

                <div class="row" style="justify-content: space-evenly;">
                    <button class="btn btn-success" data-dismiss="modal" @click="hideModel()"><span>موافق</span> </button>
                    <button class="btn btn-warning"  @click="printBill()"><span>طباعة الفاتورة</span> </button>
                </div>
			</div>
		</div>
	</div>
</div> 


</template>

<script>
 
export default {
 props:{
   title:{
     type:String,
     default:'',
   },
   message:{
     type:String,
     default:'',
   },
   back:{
     type:Boolean,
     default:false,
   },
   redirectTo:{
     type:Boolean,
     default:false,
   },
   redirectName:{
     type:String,
     default:'',
   },
   SalesItems:{
       type:Object,
       default:null,
   },
   BillInfo:{
       type:Object,
       default:null
   }
 } ,
 computed:{
     current_date(){
         return new Date().toJSON().slice(0,10);
     },
     total(){
         let total=0;
         this.SalesItems.forEach(element => {
             total+=(element.selling_price_after_discount * element.quantity)
         });
         return total;
     }
 },
methods:{
    printBill(){
          window.print();
     },
    showModel() {
   //     MyAlert("kk");
            $("#SalesBillModal").modal('show');
     },
    hideModel() {
        $("#SalesBillModal").modal('hide');
        if(this.back){
        this.$router.go(-1); 
        }
		if(this.redirectTo){
			this.$router.push({name:this.redirectName})
		}
    },
}
}
</script>

<style  lang="scss">
 .bill-header{
     margin-bottom: 16px !important;
 }
 .bill-title{
     font-weight: 500;
 }
  .bill-value{
     display: block;
 }
 .bill-logo{
     margin-bottom:16px ;
 }
  .bill-logo img{
     margin-bottom:16px ;
 }


 @media print {
  body * {
    visibility: hidden;
  }
  #bill, #bill * {
    visibility: visible;
  }
  #bill {
    position: absolute;
    left: 0;
    top: 0;
  }
}
</style>